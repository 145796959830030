import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";

import locales from "../../../constants";

const VeganCola = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";

  return (
    <Layout
      seo={{
        title: "Vegan Cola",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/vegan-cola_og_image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #66d43f, #59bf35, #50a831, #4a9c2d, #449029 )",
        icons: "#4a9c2d",
        navClass: "vegan-cola__nav",
        ogImage: require("../../../assets/img/portfolio/vegan-cola_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/vegan-cola/",
      }}
    >
      <PortfolioHeader name="vegan-cola" height="350" />
      <section
        className="portfolio-intro vegan-cola-intro vegan-cola-section vegan-cola-section__intro"
        id="info"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Vegan Cola</h1>
                <ul>
                  <li>Logo refresh</li>
                  <li>Packaging refresh</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  We have refreshed the design of the logo and the packaging of
                  the Vitamizu flagship product called "Vegan Cola", that is a
                  carbonated drink with stevia, with a cola flavor. Unlike
                  regular cola, this drink is not tested on animals, does not
                  contain preservatives, and is gluten-free.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vegan-cola-section vegan-cola-section__banner">
        <img
          className="can img-fluid"
          src={require("../../../assets/img/portfolio/vegan-cola_can.png")}
          alt="Puszka Vegan Cola"
          loading="lazy"
        />
      </section>

      <section className="vegan-cola-section vegan-cola-section__logo">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 description">
              <h2>Loga refresh</h2>
              <p>
                Our task was to lightly and neatly refresh the product logo so
                that it corresponds with the current market trends.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img
                className="sketch"
                src={require("../../../assets/img/portfolio/vegan-cola_sketch.jpg")}
                alt="Szkic loga Vegan Cola"
                loading="lazy"
              />
            </div>
          </div>
          <div className="row paper">
            <div className="col-12 col-md-6">
              <div className="logo-wrapper">
                <img
                  src={require("../../../assets/img/portfolio/vegan-cola_logo_old.svg")}
                  alt="Logo przed odświeżeniem"
                  loading="lazy"
                />
              </div>
              <p>Logo before refresh</p>
            </div>
            <div className="col-12 col-md-6">
              <img
                src={require("../../../assets/img/portfolio/vegan-cola_logo_new.svg")}
                alt="Logo po odświeżeniu"
                loading="lazy"
              />
              <p>Logo after refresh</p>
            </div>
          </div>
        </div>
      </section>

      <section className="vegan-cola-section vegan-cola-section__package">
        <div className="container-fluid">
          <div className="row align-items-center vegan-cola-section__package--cans">
            <div className="col-12 col-md-8">
              <img
                className="cans"
                src={require("../../../assets/img/portfolio/vegan-cola_cans.jpg")}
                alt="Dwie puszki Vegan Cola"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-md-4 description">
              <div className="inner">
                <h2>Packaging refresh</h2>
                <p>
                  The new packaging has been designed using intense green colors
                  to reflect the energy boost coming from the drink and
                  emphasize its eco-friendliness.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center vegan-cola-section__package--labels">
            <div className="col-12 col-md-10 order-1">
              <img
                src={require("../../../assets/img/portfolio/vegan-cola_label_old.jpg")}
                alt="Stara etykieta Vegan Cola"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-md-2 order-2">
              <p>Base project</p>
            </div>
            <div className="col-12 col-md-2 order-4 order-md-3">
              <p>Project after refreshing</p>
            </div>
            <div className="col-12 col-md-10 order-3 order-md-4">
              <img
                src={require("../../../assets/img/portfolio/vegan-cola_label_new.jpg")}
                alt="Nowa etykieta Vegan Cola"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default VeganCola;
